import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import banner_tint from "../../images/banner-tnt.png";
import top_banner from "../../images/provider_banner.png";
import num from "../../images/num.png";
// import CopyRight from "../../Components/CopyRight";

import ProviderForm from "./ProviderForm";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Provider = () => {
  return (
  <>
      <Box
      sx={{
        width: "1100px",
        margin: "auto",
        boxShadow: "0px 0px 18px 5px #0c246578",
        "@media(max-width : 1200px)": {
          width: "inherit",
          margin: "inherit",
        },
      }}
    >
      <Header />
      <Box position={"relative"}>
        {/* <Typography
          component={"img"}
          src={green_circle}
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: { sm: "60%", xs: "90%" },
            height: "100%",
          }}
        /> */}

        <Box
          sx={{
            position: "relative",
            lineHeight:"0"
          }}
        >
          <Typography
            component={"img"}
            src={top_banner}
            sx={{
              width: "100%",
              height: { sm: "auto", xs: "60vh" },
              objectFit: "cover",
            }}
          />
          <Typography
            component={"img"}
            src={banner_tint}
            sx={{
              width: {sm:"55%",xs:"100%"},
              height: { sm: "100%", xs: "100%" },
              objectFit: "cover",
              position:"absolute",
              right:"0"
            }}
          />

          <Box
            sx={{
              width: "100%",
              position: "absolute",
              top: { md: "50%", xs: "30%" },
              transform: { md: "translateY(-50%)", xs: "translateY(-30%)" },
              right: "0",
            }}
          >
            <Container
              sx={{
                display: "flex",

                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  // background: `url("${banner_tint}")`,
                  // p:"1rem",
                  backfaceVisibility: "0.1",
                  //   width: { md: "600px", xs: "100%" },
                  textAlign: { md: "start", xs: "start" },
                  "& p": {
                    fontSize: { md: "1.5rem", xs: "20px" },
                    color: "rgba(247, 235, 247, 1)",
                    fontStyle:"italic"
                    // lineHeight: "normal",
                    // pb: "0.5rem",
                  },
                }}
              >
                <Typography style={{ color: "#FDF9D8" }}>
                Advancing breathing & heath.
                </Typography>

                <Typography style={{ color: "#FDF9D8" }}>
                Enhancing professional services.
                </Typography>

                <Typography style={{ color: "#FDF9D8" }}>
                  State-of-the-art CO<sub>2</sub>{" "}
                  technology.
                </Typography>

                <Typography style={{ color: "#FDF9D8" }}>
                Unparalleled benefits.
                </Typography>
              </Box>
            </Container>
          </Box>
        </Box>
        <Container>
          <Grid container spacing={1}>
            <Grid
              item
              md={1}
              xs={1}
              sx={{
                alignSelf: "center",
                height: "100%",
                position: "relative",
                "& img": {
                  // background: "#2F45CA",
                },
              }}
            >
              <Typography component={"img"} src={num} width={"100%"} />
            </Grid>
            <Grid item md={11} xs={11}>
              <Box
                sx={{
                  py: "5rem",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: "24px", xs: "20px" },
                    color: "#000",
                    pb: "2rem",
                  }}
                >
                  RespiMatters<sup style={{ fontSize: "8px" }}>TM</sup>{" "}
                  Affiliate Service
                </Typography>

                <Typography
                  sx={{
                    fontSize: { sm: "22px", xs: "20px" },
                    color: "rgba(122, 123, 145, 1)",
                    pb: "1.5rem",
                  }}
                >
                  RespiMatters<sup style={{ fontSize: "8px" }}>TM</sup> leads
                  the way with access to expert services and advanced CO
                  <sub>2</sub> technology for
                  affiliates. Services address the role breathing habits have
                  when optimizing respiration can benefit clients within your practice and
                  provider outcomes.
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "22px", xs: "20px" },
                    color: "rgba(122, 123, 145, 1)",
                    pb: "1.5rem",
                  }}
                >
                  Let’s explore the possibility of affiliating!
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "22px", xs: "20px" },
                    color: "rgba(122, 123, 145, 1)",
                    pb: "1.5rem",
                  }}
                >
                  Please complete the information below and click on "Send my
                  Affiliate Inquiry." <br />
                  We will get back to you soon.
                </Typography>

                <ProviderForm />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Box>
          
  </>
  );
};

export default Provider;
