import * as Yup from "yup";

export const getInitialValues = () => {
  return {
    name: "",
    businessName: "",
    email: "",
    phone: "",
    heardFrom: "",
  };
};

export const validationSchema = Yup.object({
  name: Yup.string()
    .trim("Name should not contain spaces")
    .required("Name is required"),
  businessName: Yup.string()
    .trim("Business Name should not contain spaces")
    .required("Business Name is required"),
  heardFrom: Yup.string()
    .trim("This Field should not contain spaces"),

  phone: Yup.string()
    .trim()
    .test("phone", "Phone number must contain only numeric digits", (value) => {
      if (!value) {
        // Allow empty values, assuming they are valid
        return true;
      }
      return /^[0-9()-]+$/.test(value);
    })
    .matches(/^[0-9()-]+$/, "Invalid phone number format")
    .required("Phone number is required"),
  email: Yup.string()
    .trim("Business should not contain spaces")
    .email("Invalid email address")
    .required("Business email is required"),
});
