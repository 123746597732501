import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import brainimg from '../../src/images/brainimg.jpg'
import Testimonial from "./Testimonial";



const useStyles = makeStyles({
    main__box: {
        textAlign: 'center',
        padding: '4rem 0rem'
    },

    h6: {
        fontFamily: 'Candara !important',
        fontSize: '35px !important',
        '@media(max-width : 600px)': {
            fontSize: '24px !important',
            textAlign: 'center'
        }
    },
    p: {
        fontSize: '16px !important',
        fontStyle: 'italic !important',
        padding: '0rem 12rem',
        marginTop: '2rem !important',
        '@media(max-width : 900px)': {
            padding: '0rem 0rem',
        }
    },
    p2: {
        fontSize: '16px !important',
        fontStyle: 'italic !important',
        padding: '0rem 12rem',
        marginTop: '1rem !important',
        '@media(max-width : 900px)': {
            padding: '0rem 0rem',
        }
    },

})

const Whypeoplechoose = () => {
    const classes = useStyles();
    return (
        <>
            <Container>
                <Box className={classes.main__box}>
                    <Typography color={'#0c69b8'} className={classes.h6} variant="h3">Why people choose RespiMatters<Typography color={'#0c69b8'} fontSize={10} component={'sup'}>TM</Typography></Typography>
                    {/* <Typography className={classes.p} color={'#3288bd'}>” My health provider referred me for these services because my breathing was not supporting my respiration based on testing at his office.  I assumed that just because I was breathing that was enough. I am happy to say I was wrong.  My breathing and respiration improved in more ways than I ever could have ever imagined working with Sandra. I discovered breathing could be easier rather than difficult, my airway behavior improved, anxiety lifted, sleep became my friend rather than a tussle.  After 3 years of not being able to be physically active at all, I can chase my dog again, dodging, and darting. The physical pain I experienced was reduced more and more, then disappearing. The changes I’ve made have returned me to myself.  I’m well on my way to feeling better, more active, and freer from challenges I was experiencing. These services have helped me immensely.” </Typography>
                    <Typography className={classes.p2} color={'#3288bd'}>(JS, high school student)</Typography> */}
                    <Testimonial />
                </Box>
            </Container>
        </>
    )
}

export default Whypeoplechoose;