import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import techer from '../../src/images/techer.png'
import { Link } from "react-router-dom";
import btnbg from '../../src/images/btnbg.png'
import easyonlinebg from '../../src/images/easyonlinebg.png'



const useStyles = makeStyles({


    main__div: {
        padding: '10rem 0rem 10rem 0rem',

        '@media(max-width : 600px)': {
            padding: '3rem 0rem 2rem 0rem'
        }
    },

    h6: {

    },
    p: {
        fontSize: '16px !important',

    },
    left: {
        textAlign: 'left',
        '@media(max-width : 600px)': {
            textAlign: 'center'
        }
    },
    right: {
        textAlign: 'left',
        paddingLeft: '4rem',
        '@media(max-width : 600px)': {
            paddingLeft: '0rem',
            textAlign: 'center',
            marginTop: '1rem'
        }
    },
    es__box: {
        display: 'flex',
        justifyContent: 'center',
        '@media(max-width : 600px)': {
            display: 'inherit',
            textAlign: 'center'
        }
    }

})

const EasyOnline = () => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.main__div}>
                <Container>

                    <Box className={classes.es__box}>
                        <Box className={classes.left}>
                            <Box >
                                <Typography color={'#1480cb'} className={classes.h6} variant="h5">RespiMatters<Typography fontSize={8} color={'#1480cb'} component={'sup'}>TM</Typography></Typography>
                                <Typography color={'#1480cb'} className={classes.p}>Optimize breathing, respiration, & health.</Typography>
                            </Box>
                            <Box sx={{ marginTop: '0.7rem' }}>
                                <Typography color={'#1480cb'} className={classes.p}>RespiMatters.com</Typography>
                                <Typography color={'#1480cb'} className={classes.p}>info@RespiMatters.com</Typography>
                            </Box>
                        </Box>


                        <Box className={classes.right}>
                            <Box>
                                <Typography color={'#1480cb'} className={classes.p}>Easy online scheduling. <Box component={'br'} /> Personalized tele-learning services.
                                {/* <Typography fontSize={8} color={'#1480cb'} component={'sup'}>TM</Typography> */}
                                </Typography>
                                <Typography sx={{ marginTop: '2rem' }} color={'#1480cb'} className={classes.p}> HOURS <Box component={'br'} /> 9:15am to 5pm Mon-Frid (MST) <Box component={'br'} /> Evening hours vary. <Box component={'br'} /> Limited weekend hours.</Typography>
                            </Box>

                        </Box>
                    </Box>

                </Container>
            </Box>
        </>
    )
}

export default EasyOnline;