import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Services from "../../components/Services";
import Help from "../../components/Help";
import Brain from "../../components/Brain";
import { Box } from "@mui/material";
import Whypeoplechoose from "../../components/Whypeoplechoose";
import StepConsultation from "../../components/StepConsultation";
import Teacher from "../../components/Teacher";
import EasyOnline from "../../components/EasyOnline";
import Footer from "../../components/Footer";
import BannerSlider from "../../components/BannerSlider";


const Home = () => {
    return (
        <>
            <Box sx={{
                width: '1100px',
                margin: 'auto',
                boxShadow: '0px 0px 18px 5px #0c246578',
                '@media(max-width : 1200px)': {
                    width: 'inherit',
                    margin: 'inherit'
                }
            }}>
                <Header />
                <BannerSlider />
                <Services />
                <Help />
                <Brain />
                <Whypeoplechoose />
                <StepConsultation />
                <Teacher />
                <EasyOnline />

            <Footer />
            </Box>
        </>
    )
}

export default Home;