import { Box, Container, Typography, Button } from "@mui/material";
import React from "react";
import success from "../../images/success.png";
import { Link } from "react-router-dom";

const ThankyouPage = () => {


  return (
    <Container>
      <Box
        sx={{
          width: { md: "50%", xs: "auto" },
          margin: "5rem auto 2rem",
          borderRadius: "10px",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          padding: {md:"5rem",xs:"2rem"},
          textAlign: "center",
          height: {sm:"400px",xs:"300px"},
          boxShadow: "rgb(146, 141, 191) 0px 2px 12px",
        }}
      >
        <Box>
          <Box
            sx={{
              color: "#2e72c5",
              // fontFamily:"myriadRegular",
              fontSize: {sm:"2rem",xs:"1.5rem"},
              mb: "2rem",
              display: "flex",
              alignItems: "center",
              flexDirection: { md: "column", xs: "column" },
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                // background: "green",
                height: { sm: "100px", xs: "60px" },
                width: { sm: "100px", xs: "60px" },
                // borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pb: "1rem",
              }}
            >
              <Typography
                component={"img"}
                src={success}
                sx={{
                  width: { sm: "100%", xs: "100%" },
                }}
              />
            </Box>
            Affiliate Inquiry has been submitted successfully.
          </Box>
              <Box textAlign={"center"}>
              <Button
                 sx={{
            
                  background: "#4a90e2 !important",
                  color: "#fff !important",
                  // fontFamily: "centuryGothic",
                  p: "0.7rem",
                  fontSize: "18px !important",
                  lineHeight: "initial !important",
                  border: "2px solid #fff !important",
                  width: {sm:"60%",xs:"80%"},
                  "&:hover": {
                    background: "#4a90e2",
                  },
                }}
                component={Link} to="/"
                >Back to website</Button>
              </Box>
       
         
        </Box>
      </Box>

      <Box sx={{
        pt:{sm:"10rem",xs:"5rem"}
      }}>
        {/* <Copyright /> */}
      </Box>
    </Container>
  );
};

export default ThankyouPage;