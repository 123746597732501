import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import techer from "../../src/images/techer.png";
import { Link } from "react-router-dom";
import btnbg from "../../src/images/btnbg.png";
import easyonlinebg from "../../src/images/easyonlinebg.png";
import bluewave from "../../src/images/bluewave .png";

const useStyles = makeStyles({
  main__div: {
    backgroundColor: "#abe1e5",
    padding: "5rem 0rem",
    "@media(max-width : 600px)": {
      padding: "7rem 0rem",
    },
  },

  br: {
    "@media(max-width : 600px)": {
      display: "none !important",
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            "& img": {
              height: { md: "420px", xs: "200px" },
              // objectFit:"cover"
            },
          }}
        >
          <Box component={"img"} src={bluewave} width={"100%"} />
        </Box>
        <Box className={classes.main__div}>
          <Container
            sx={{
              "& p": {
                position: "relative",
                fontSize: "13px",
                color: "#164e70",
              },
              "& a": {
                position: "relative",
                fontSize: "16px",
                color: "#164e70",
                textDecoration: "none",
              },
            }}
          >
            <Grid container spacing={4}>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  mt: { md: "1.6rem" },
                  "& .p": {
                    pb: "0.5rem",
                  },
                }}
              >
                <Typography className="p">
                  {" "}
                  <Link to={"/"}>
                    Home-Why RespiMatters<sup style={{ fontSize: 8 }}>TM</sup>
                  </Link>
                </Typography>
                <Typography className="p">
                  <a href={"https://booking.respimatters.com/"} target="_blank">
                    Individual Services
                  </a>
                </Typography>
                <Typography>
                  <Link to={"/affiliates"}>Affiliates</Link>
                </Typography>
              </Grid>
              <Grid item md={3.5} xs={12} alignSelf={"center"}>
                <Typography sx={{ pt: { md: "2.5rem" } }}>
                  This site does not address life threatening situations. For
                  immediate help call, text, or chat 988 or 1800-273-TALK in the
                  USA.
                </Typography>
              </Grid>
              <Grid item md={5.5} xs={12}>
                <Box
                  sx={{
                    width: { md: "95%" },
                  }}
                >
                  <Typography>
                    Medical Advice Disclaimer: These statements have not been
                    evaluated by the food and drug administration. This website
                    and its contents are not intended to diagnose, treat, cure,
                    or prevent disease. Purchases acknowledge that you have read
                    and understand our medical disclaimer.
                  </Typography>
                  <Typography pt={"1rem"}>
                    *RespiMatters<sup style={{ fontSize: 8 }}>TM</sup> utilizes
                    instrumentation that measures CO<sub>2</sub> concentration
                    in the alveoli of the lungs. This instrumentation is
                    approved by the FCC, CE, UK CA. This instrument is not
                    verified by the FDA. Statements on this site are for
                    educational purposes.
                  </Typography>
                  <Typography pt={"1rem"}>
                    © 2002-2025 Copyright Sandra Reamer | All rights reserved.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
