import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import { getInitialValues, validationSchema } from "./FormConfig";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import TimezoneSelect from "react-timezone-select";
import { API_URL } from "../../components/config";

const ProviderForm = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const initialValues = getInitialValues();
  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
      //   navigate("/success")

      setLoader(true);
      try {
        const res = await axios.post(
          `${API_URL}/create/new/affiliate`,
          { ...values },
          {
            headers: {
              //   "x-access-token": token,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 200) {
          setLoader(false);
          navigate("/success");
          resetForm();
          console.log(res);
        }
      } catch (err) {
        setLoader(false);
        console.log(err);
      }
    },
  });

  const getTextWidth = (text, font = "16px Arial") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
  };
  const calculateWidth = (text, maxWidth = 600) => {
    const width = Math.max(200, getTextWidth(text || "", "16px Arial") + 10); // Minimum width of 100px
    return width > maxWidth ? maxWidth : width; // Constrain to maxWidth
  };

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 2,
        "& .bin_2": {
          background: "transparent",
          color: "#fff",
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&:focus-Visible": {
            outline: "none !important",
            border: "none !important",
          },
          "&:hover": {
            border: "none",
          },
          "& .MuiInputBase-root": {
            "&::before": {
              display: "none",
            },
            "&::after": {
              display: "none",
            },
          },

          "&:focus": {
            boxShadow: "none !important",
            border: "1px solid #fff !important",
            borderRadius: "8px !important ",
          },
          "& input": {
            padding: "0px",
            // width: "100%",
            color: "#000",
            borderBottom: "1px solid",
            height: "28px",
            "&:focus": {
              outline: "none",
            },
            "&::placeholder": {
              color: "#0000008f !important",
              fontFamily: "myriadRegular",
            },
          },
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography
          sx={{
            fontSize: { sm: "22px", xs: "20px" },
            color: "rgba(122, 123, 145, 1)",
            pb: "1.5rem",
          }}
        >
          AFFILIATE INQUIRY
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "22px", xs: "20px" },
            color: "rgba(122, 123, 145, 1)",
            pb: "1.5rem",
          }}
        >
          Your Name:{" "}
          <TextField
            sx={{
              mx: "0.2rem",
              "& input": {
                width: { md: calculateWidth(formik.values.name), xs: "100%" },
                borderBottom:
                  formik.touched.name && formik.errors.name
                    ? "1px solid red !important"
                    : "1px solid",
              },
            }}
            autoComplete="off"
            variant="standard"
            type="text"
            placeholder=""
            id="name"
            name="name"
            className="bin_2"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "22px", xs: "20px" },
            color: "rgba(122, 123, 145, 1)",
            pb: "1.5rem",
          }}
        >
          Name of Business:{" "}
          <TextField
            sx={{
              mx: "0.2rem",
              "& input": {
                width: {
                  md: calculateWidth(formik.values.businessName),
                  xs: "100%",
                },
                borderBottom:
                  formik.touched.businessName && formik.errors.businessName
                    ? "1px solid red !important"
                    : "1px solid",
              },
            }}
            autoComplete="off"
            variant="standard"
            type="text"
            placeholder=""
            id="businessName"
            name="businessName"
            className="bin_2"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "22px", xs: "20px" },
            color: "rgba(122, 123, 145, 1)",
            pb: "1.5rem",
          }}
        >
          Phone Number:{" "}
          <TextField
            sx={{
              mx: "0.2rem",
              "& input": {
                width: { md: calculateWidth(formik.values.phone), xs: "100%" },
                borderBottom:
                  formik.touched.phone && formik.errors.phone
                    ? "1px solid red !important"
                    : "1px solid",
              },
            }}
            autoComplete="off"
            variant="standard"
            type="text"
            placeholder=""
            id="phone"
            name="phone"
            className="bin_2"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "22px", xs: "20px" },
            color: "rgba(122, 123, 145, 1)",
            pb: "1.5rem",
          }}
        >
          E-mail:{" "}
          <TextField
            sx={{
              mx: "0.2rem",
              "& input": {
                width: { md: calculateWidth(formik.values.email), xs: "100%" },
                borderBottom:
                  formik.touched.email && formik.errors.email
                    ? "1px solid red !important"
                    : "1px solid",
              },
            }}
            autoComplete="off"
            variant="standard"
            type="email"
            placeholder=""
            id="email"
            name="email"
            className="bin_2"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "22px", xs: "20px" },
            color: "rgba(122, 123, 145, 1)",
            pb: "1.5rem",
          }}
        >
          How did you hear about us?{" "}
          <TextField
            sx={{
              mx: "0.2rem",
              "& input": {
                width: { md: calculateWidth(formik.values.heardFrom), xs: "100%" },
                borderBottom:
                  formik.touched.heardFrom && formik.errors.heardFrom
                    ? "1px solid red !important"
                    : "1px solid",
              },
            }}
            autoComplete="off"
            variant="standard"
            type="text"
            placeholder=""
            id="heardFrom"
            name="heardFrom"
            className="bin_2"
            value={formik.values.heardFrom}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />{" "}
          (Optional)
        </Typography>

        <Button
          type="submit"
          className="btn"
          sx={{
            mt: "2rem",
            mb: "2rem",
            border: "1px solid rgba(121, 123, 145, 1)",
            color: "rgba(121, 123, 145, 1) !important",
            px: "2rem",
            py: loader ? "12px" : "6px",
            fontSize: "16px",
            fontWeight: "600 !important",
            textTransform: "capitalize",
            width: loader ? "150px !important" : "auto !important",
          }}
        >
          {loader ? (
            <CircularProgress
              sx={{
                width: "16px !important",
                height: "16px !important",
                color: "#000 !important",
              }}
            />
          ) : (
            "Send my Affiliate Inquiry form "
          )}
        </Button>
        <Box
          sx={{
            "& p": {
              fontSize: "14px",
              color: "red",
            },
          }}
        >
          {formik.touched.name && formik.errors.name && (
            <Typography>{formik.errors.name}</Typography>
          )}
          {formik.touched.businessName && formik.errors.businessName && (
            <Typography>{formik.errors.businessName}</Typography>
          )}
          {formik.touched.phone && formik.errors.phone && (
            <Typography>{formik.errors.phone}</Typography>
          )}
          {formik.touched.email && formik.errors.email && (
            <Typography>{formik.errors.email}</Typography>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default ProviderForm;
