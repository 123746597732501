import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    // Disable smooth scroll for route change
    document.documentElement.style.scrollBehavior = "auto";

    // Scroll to top whenever the route changes
    window.scrollTo(0, 0);

    // Restore smooth scroll after a brief delay (or after the route change)
    const timeout = setTimeout(() => {
      document.documentElement.style.scrollBehavior = "smooth";
    }, 2000);

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, [location]);

  return null;
};



export default ScrollToTop;
